/* Styling for the ngProgress itself */
#ngProgress {
    margin: 0;
    padding: 0;
    z-index: 99998;
    background-color: green;
    color: green;
    box-shadow: 0 0 10px 0; /* Inherits the font color */
    height: 2px;
    opacity: 0;

    /* Add CSS3 styles for transition smoothing */
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

/* Styling for the ngProgress-container */
#ngProgress-container {
    position: fixed;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
}
